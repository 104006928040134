<template>
  <div class="conversation" :class="{ channels: channelsTab }">
    <div v-if="messageList.length > 0" class="conversation-container">
      <div
        v-for="(message, index) in messageList"
        v-bind:class="message.type"
        class="message"
        :key="index"
      >
        <span v-text="message.text" class="text"></span>
        <span class="metadata">
          <span v-text="message.time" class="time"></span>
          <span v-if="message.type === 'sent'" class="tick">
            <i class="glyphter icon-read"></i>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    messages: {
      required: true,
      type: Array,
    },
    iterate: {
      required: true,
      type: Boolean,
    },
    channelsTab: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      messageList: [],
    };
  },
  watch: {
    messages() {
      this.renderMessages();
    },
  },
  mounted() {
    this.renderMessages();
  },
  methods: {
    async renderMessages() {
      let results = [];
      this.messages.forEach((message, i) => {
        results.push(this.addMessage(message, i));
      });
      await Promise.all(results);
      results = [];
      for (let i = 0; i < this.messages.length; i += 1) {
        results.push(this.removeMessage(i));
      }
      await Promise.all(results);
      this.notifyCompletion();
    },
    addMessage(msg, i) {
      return new Promise((resolve) => {
        setTimeout(() => resolve(this.messageList.push(msg)), (i + 1) * 3000);
      });
    },
    removeMessage(i) {
      return new Promise((resolve) => {
        setTimeout(() => resolve(this.messageList.pop()), (i + 1) * 2000);
      });
    },
    notifyCompletion() {
      if (this.iterate) {
        this.renderMessages();
      } else {
        this.messageList = [];
        this.$emit('animationCompleted');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_chat-preview.scss";
</style>
